import axios from 'axios'
import { useEffect } from 'react'
import useSWR from 'swr'

import nextFetcher from '@miimosa/api/nextFetcher'
import type { VoteResult } from '@miimosa/common/types'
import { usePrevious } from '@miimosa/design-system'
import { usePersistedState } from '@miimosa/design-system/lib/hooks'

import useQRCode from './useQRCode'

const VOTE_VALUE = 1
// 15min
const VOTE_EXPIRE_MS = 15 * 60 * 1000
const MAX_VOTES = 2

interface ProjectVoteReturn {
  canVote: boolean
  votedSlugs: string[]
  addVote: (slug: string) => Promise<void>
  getProjectAmount: (slug: string) => string
  totalAmount: number
}

interface VoteState {
  stamp: number | null
  slugs: string[]
}

// Video vote creates an array of voted slugs as eg: [ project1, project2]
// these slugs must match a dynamic list of autorized slugs correspondig to the 2 active projects
// (2 active projects per 2 month)

function useVideoVote(authorizedSlugs: string[]): ProjectVoteReturn {
  const { qrCodeActive, consumeQRCode } = useQRCode()
  const { data: votes, mutate } = useSWR<VoteResult>('/nextapi/vote', nextFetcher, {
    fallbackData: { total_vote_count: 0, projects: [] },
  })
  const [videoVotes, setVideoVotes] = usePersistedState<VoteState | undefined>('innocent_video_votes', undefined)
  const votedSlugs = videoVotes?.slugs ?? []
  const maxVoteIsReached = votedSlugs.length >= MAX_VOTES
  const canVote = !maxVoteIsReached && qrCodeActive

  // TODO: add faking
  const totalAmount = (votes?.total_vote_count ?? 0) * VOTE_VALUE

  const hasVotedAndTimeExpired =
    maxVoteIsReached && videoVotes && videoVotes.stamp && videoVotes.stamp < Date.now() - VOTE_EXPIRE_MS

  const previousQrCodeActive = usePrevious(qrCodeActive)

  useEffect(() => {
    if (previousQrCodeActive && !qrCodeActive) {
      setVideoVotes({ slugs: [], stamp: null })
    }
  }, [previousQrCodeActive, setVideoVotes, qrCodeActive])

  useEffect(() => {
    if (hasVotedAndTimeExpired) {
      setVideoVotes({ slugs: [], stamp: null })
    }
  }, [hasVotedAndTimeExpired, setVideoVotes, qrCodeActive])

  return {
    canVote,
    votedSlugs,
    addVote: async (slug: string) => {
      // we exit if slug isnt in the ok list or is already voted
      if (!authorizedSlugs.includes(slug) || votedSlugs.includes(slug)) return

      setVideoVotes({ stamp: new Date().getTime(), slugs: [...(videoVotes?.slugs ? videoVotes.slugs : []), slug] })

      // we must consume QR code if the votedSlugs length + 1 equals authorizedSlugs length (2)
      const mustConsumeQRCode = votedSlugs?.length + 1 === authorizedSlugs.length ?? (false || !canVote)

      if (mustConsumeQRCode) {
        consumeQRCode()
      }

      await axios.post('/nextapi/vote', { slug })
      mutate()
    },
    getProjectAmount: (slug: string) => {
      if (!votes) return '0'

      for (const p of votes.projects) {
        if (p.slug === slug) {
          return (p.total_vote_count * VOTE_VALUE).toFixed(2)
        }
      }
      return '0'
    },
    totalAmount,
  }
}

export default useVideoVote
